var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"align-start",attrs:{"fluid":"","fill-height":""}},[(_vm.me)?_c('change-player-details-dialog',{attrs:{"visible":_vm.isPlayerDialogVisible,"value":_vm.me},on:{"update:visible":function($event){_vm.isPlayerDialogVisible=$event},"apply-changes":_vm.changePlayer}}):_vm._e(),(_vm.room)?_c('room-configuration-dialog',{attrs:{"visible":_vm.isConfigurationVisible,"value":_vm.room.configuration},on:{"update:visible":function($event){_vm.isConfigurationVisible=$event},"apply-changes":_vm.changeRoomConfiguration}}):_vm._e(),_c('v-row',{attrs:{"align":"stretch"}},[_c('v-col',{staticClass:"d-flex flex-column",class:{ 'min-height-92vh': _vm.$vuetify.breakpoint.smAndDown },attrs:{"cols":"12","md":"9","xl":"10"}},[_c('div',{staticClass:"text-h4 text-center mt-0 mt-md-2 flex-grow-0 flex-shrink-0",domProps:{"textContent":_vm._s(_vm.topic)}}),_c('v-divider',{staticClass:"my-2 my-md-4"}),(_vm.room)?_c('div',{staticClass:"d-flex align-center flex-grow-0 flex-shrink-0"},[(!_vm.amIObserver)?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","outlined":"","disabled":_vm.configDisabled},on:{"click":function($event){_vm.isConfigurationVisible = true}}},on),[_c('v-icon',{attrs:{"dark":"","large":""}},[_vm._v(" mdi-cog-outline ")])],1)]}}],null,false,1496843037)},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltips.config")))])]):_vm._e(),_c('start-round-button',{staticClass:"mx-auto",attrs:{"room":_vm.room,"disabled":_vm.amIObserver},on:{"start-new-round":_vm.startNewRound,"stop-round":_vm.stopRound}}),(!_vm.amIObserver)?_c('v-tooltip',{attrs:{"bottom":"","open-delay":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ma-3",attrs:{"fab":"","outlined":""},on:{"click":_vm.reset}},on),[_c('v-icon',{attrs:{"dark":"","large":""}},[_vm._v(" mdi-skull-outline")])],1)]}}],null,false,4257267682)},[_c('span',[_vm._v(_vm._s(_vm.$t("tooltips.reset")))])]):_vm._e()],1):_vm._e(),(_vm.room && _vm.room.configuration)?_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-shrink-1 justify-space-between mt-4"},[_c('showdown',{attrs:{"room":_vm.room,"my-id":_vm.me ? _vm.me.id : ''}}),_c('card-picker',{attrs:{"card-values":_vm.room.configuration.cards,"selected-card":_vm.card,"can-play":!_vm.amIObserver && _vm.room.state === 1},on:{"update:selectedCard":[function($event){_vm.card=$event},_vm.playCardAsync],"update:selected-card":function($event){_vm.card=$event}}})],1):_vm._e()],1),_c('v-col',{class:{
        'max-height-92vh': _vm.$vuetify.breakpoint.mdAndUp,
        'overflow-y-auto': _vm.$vuetify.breakpoint.mdAndUp
      },attrs:{"cols":"12","md":"3","xl":"2"}},[_c('div',{staticClass:"text-h4 mx-4 mt-0 mt-md-2",domProps:{"textContent":_vm._s(_vm.$t('player-list'))}}),_c('v-divider',{staticClass:"mt-2 mt-md-4 mb-4"}),_c('player-list',{attrs:{"players":_vm.players,"p-gu-id":_vm.me ? _vm.me.id : ''},on:{"change-player-name":_vm.showChangePlayerDetailsDialog}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }