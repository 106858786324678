<template>
  <v-container fluid fill-height class="align-start">
    <change-player-details-dialog
      v-if="me"
      :visible.sync="isPlayerDialogVisible"
      :value="me"
      @apply-changes="changePlayer"
    />
    <room-configuration-dialog
      v-if="room"
      :visible.sync="isConfigurationVisible"
      :value="room.configuration"
      @apply-changes="changeRoomConfiguration"
    />
    <v-row align="stretch">
      <v-col
        cols="12"
        md="9"
        xl="10"
        class="d-flex flex-column"
        :class="{ 'min-height-92vh': $vuetify.breakpoint.smAndDown }"
      >
        <div class="text-h4 text-center mt-0 mt-md-2 flex-grow-0 flex-shrink-0" v-text="topic" />
        <v-divider class="my-2 my-md-4" />
        <div v-if="room" class="d-flex align-center flex-grow-0 flex-shrink-0">
          <v-tooltip v-if="!amIObserver" bottom open-delay="400">
            <template v-slot:activator="{ on }">
              <v-btn
                fab
                outlined
                :disabled="configDisabled"
                @click="isConfigurationVisible = true"
                v-on="on"
              >
                <v-icon dark large> mdi-cog-outline </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltips.config") }}</span>
          </v-tooltip>

          <start-round-button
            :room="room"
            :disabled="amIObserver"
            class="mx-auto"
            @start-new-round="startNewRound"
            @stop-round="stopRound"
          />

          <v-tooltip v-if="!amIObserver" bottom open-delay="400">
            <template v-slot:activator="{ on }">
              <v-btn fab outlined class="ma-3" @click="reset" v-on="on">
                <v-icon dark large> mdi-skull-outline</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltips.reset") }}</span>
          </v-tooltip>
        </div>
        <div
          v-if="room && room.configuration"
          class="d-flex flex-column flex-grow-1 flex-shrink-1 justify-space-between mt-4"
        >
          <showdown :room="room" :my-id="me ? me.id : ''" />
          <card-picker
            :card-values="room.configuration.cards"
            :selected-card.sync="card"
            :can-play="!amIObserver && room.state === 1"
            @update:selectedCard="playCardAsync"
          />
        </div>
      </v-col>
      <v-col
        cols="12"
        md="3"
        xl="2"
        :class="{
          'max-height-92vh': $vuetify.breakpoint.mdAndUp,
          'overflow-y-auto': $vuetify.breakpoint.mdAndUp
        }"
      >
        <div class="text-h4 mx-4 mt-0 mt-md-2" v-text="$t('player-list')" />
        <v-divider class="mt-2 mt-md-4 mb-4" />
        <player-list
          :players="players"
          :p-gu-id="me ? me.id : ''"
          @change-player-name="showChangePlayerDetailsDialog"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { Room } from "../scrumpoker/models/room.js";
import ScrumPokerHubClient from "../scrumpoker/bi/scrumPokerHubClient.js";
import { v4 as uuidv4 } from "uuid";
import { RoomState } from "../scrumpoker/models/roomState.js";
import { RoomConfiguration } from "../scrumpoker/models/roomConfiguration.js";
import { PlayerStates } from "../scrumpoker/models/playerStates.js";

const StartRoundButton = () => import("../scrumpoker/components/StartRoundButton");
const Showdown = () => import("../scrumpoker/components/Showdown");
const CardPicker = () => import("../scrumpoker/components/CardPicker.vue");
const PlayerList = () => import("../scrumpoker/components/PlayerList");
const ChangePlayerDetailsDialog = () =>
  import("../scrumpoker/components/ChangePlayerDetailsDialog");
const RoomConfigurationDialog = () =>
  import("../scrumpoker/components/RoomConfigurationDialog.vue");

export default {
  name: "ScrumPoker",

  components: {
    StartRoundButton,
    Showdown,
    CardPicker,
    PlayerList,
    RoomConfigurationDialog,
    ChangePlayerDetailsDialog
  },

  data() {
    return {
      /** @type {ScrumPokerHubClient} */
      scrumPokerHubClient: null,
      /** @type {boolean} */
      isPlayerDialogVisible: false,
      /** @type {Room} */
      room: null,
      /** @type {string} */
      id: null,
      /** @type {boolean} */
      isConfigurationVisible: false,
      /** @type {Card} */
      card: null,
      /** @type {boolean} */
      askForPlayername: false
    };
  },

  computed: {
    me() {
      return this.room ? this.room.me : null;
    },

    amIObserver() {
      let myPlayerState = this.me ? this.me.state : PlayerStates.isObserver;
      return (myPlayerState & PlayerStates.isObserver) === PlayerStates.isObserver;
    },

    players() {
      return this.room && this.room.players ? this.room.players : {};
    },

    topic() {
      return this.room &&
        this.room.configuration &&
        this.room.configuration.topic &&
        this.room.configuration.topic !== ""
        ? this.room.configuration.topic
        : this.$t("default-topic");
    },

    configDisabled() {
      return this.room.state != RoomState.BetweenRound;
    }
  },

  async mounted() {
    await this.initializeAsync();
    this.isPlayerDialogVisible = this.askForPlayername;

    document.addEventListener("visibilitychange", this.onVisibilityChange);
  },

  methods: {
    /** Establishes a connection with the scrum poker hub specified in the
     *  config file.
     */
    async initializeAsync() {
      this.isPlayerDialogVisible = false;
      const hubUrl = this.$config.scrumpokerHubUrl;
      this.scrumPokerHubClient = new ScrumPokerHubClient(hubUrl);
      await this.scrumPokerHubClient.initializeAsync();

      this.scrumPokerHubClient.onSetRoom = this.onSetRoom;
      this.scrumPokerHubClient.onPlayerChanged = this.onPlayerChanged;
      this.scrumPokerHubClient.onRoomStateChanged = this.onRoomStateChanged;
      this.scrumPokerHubClient.onRoomConfigChanged = this.onRoomConfigChanged;
      this.scrumPokerHubClient.onCurrentRoundChanged = this.onCurrentRoundChanged;
      this.scrumPokerHubClient.onRoomResetted = this.onRoomResetted;

      let id = localStorage.getItem("scrumpoker-playerid");
      if (id) {
        this.id = id;
      } else {
        this.id = uuidv4();
        this.askForPlayername = true;
        localStorage.setItem("scrumpoker-playerid", this.id);
      }

      await this.scrumPokerHubClient.enterRoomAsync(this.id);
    },

    async onVisibilityChange() {
      let myPlayerState = this.me ? this.me.state : PlayerStates.isObserver;

      if (document.visibilityState === "hidden")
        await this.scrumPokerHubClient.changePlayerStateAsync(
          this.id,
          myPlayerState | PlayerStates.isIdle
        );
      else if (myPlayerState & PlayerStates.isIdle)
        await this.scrumPokerHubClient.changePlayerStateAsync(
          this.id,
          myPlayerState ^ PlayerStates.isIdle
        );
    },

    onSetRoom(room) {
      this.room = room;
      this.room.me = room.players[this.id];
    },

    async onRoomResetted() {
      await this.scrumPokerHubClient.enterRoomAsync(this.id);
      this.isPlayerDialogVisible = true;
    },

    onPlayerChanged(players) {
      this.room.players = players;
      this.room.me = this.room.players[this.id];
    },

    onRoomStateChanged(state) {
      if (this.room.state === state) return;
      this.room.state = state;
      if (state !== RoomState.InRound) this.card = null;
    },

    onRoomConfigChanged(configuration) {
      if (!this.room) return;
      this.room.configuration = configuration;
    },

    onCurrentRoundChanged(round) {
      round.moves = Object.values(round.moves);
      this.room.currentRound = round;
    },

    async reset() {
      await this.scrumPokerHubClient.resetRoomAsync(this.id);
    },

    async changePlayer(player) {
      await this.scrumPokerHubClient.changePlayerAsync(player);
    },

    async changeRoomConfiguration(configuration) {
      if (!configuration || !this.room) return;
      if (!this.room.configuration) this.room.configuration = new RoomConfiguration("", 0, []);

      let promises = [];
      if (configuration.topic !== this.room.configuration.topic)
        promises.push(this.scrumPokerHubClient.changeRoomTopicAsync(configuration.topic));

      if (configuration.roundTimeInSeconds !== this.room.configuration.roundTimeInSeconds)
        promises.push(
          this.scrumPokerHubClient.changeRoundTimeAsync(
            Number.parseInt(configuration.roundTimeInSeconds)
          )
        );

      promises.push(this.scrumPokerHubClient.changeCardValuesAsync(configuration.cards));

      await Promise.all(promises);
    },

    async playCardAsync(card) {
      if (!this.room || this.room.state !== RoomState.InRound) this.card = null;
      else {
        if (card === null) await this.scrumPokerHubClient.withdrawCardAsync(this.me.id);
        else await this.scrumPokerHubClient.playCardAsync(this.me.id, card);
      }
    },

    showChangePlayerDetailsDialog() {
      this.isPlayerDialogVisible = true;
    },

    async startNewRound() {
      if (this.scrumPokerHubClient) await this.scrumPokerHubClient.startNewRoundAsync();
    },

    async stopRound() {
      if (this.scrumPokerHubClient) await this.scrumPokerHubClient.stopRoundAsync();
    }
  }
};
</script>

<style lang="scss" scoped>
.min-height-92vh {
  min-height: 92vh;
}

.max-height-92vh {
  height: 92vh;
  max-height: 92vh;
}

.overflow-y-auto {
  overflow-y: auto;
}
</style>

<i18n>
  {
    "de": {
      "player-list": "Mitspieler",
      "default-topic": "Scrum Poker",
      "tooltips": {
        "reset": "Raum zurücksetzen",
        "config": "Konfiguration aufrufen"
      }
    },

    "en": {
      "player-list": "Players",
      "default-topic": "Scrum Poker",
      "tooltips": {
        "reset": "Reset room",
        "config": "Open configuration"
      }
    }
  }
</i18n>
