import * as SignalR from "@microsoft/signalr";

/**
 *  Names of events which can be emitted by the hub, i.e.,
 *  calling direction is: server -> client.
 */
const PLAYER_CHANGED = "PlayerChanged";
const ROOM_STATE_CHANGED = "RoomStateChanged";
const ROOM_CONFIG_CHANGED = "RoomConfigurationChanged";
const CURRENT_ROUND_CHANGED = "CurrentRoundChanged";
const SET_ROOM = "SetRoom";
const ROOM_RESETTED = "RoomResetted";

/**
 *  Names of methods which can be called by the client, i.e.,
 *  calling direction is: client -> server.
 */
const ENTER_ROOM = "EnterRoom";
const RESET_ROOM = "ResetRoom";
const CHANGE_PLAYER = "ChangePlayer";
const CHANGE_PLAYER_STATE = "ChangePlayerState";
const CHANGE_CARD_VALUES = "ChangeCardValues";
const CHANGE_ROUND_TIME = "ChangeRoundTime";
const CHANGE_ROOM_TOPIC = "ChangeRoomTopic";
const START_NEW_ROUND = "StartNewRound";
const STOP_ROUND = "StopRound";
const PLAY_CARD = "PlayCard";
const WITHDRAW_CARD = "WithdrawCard";

/**
 *  An error handler considering errors thrown when
 *  interacting with the scrum poker hub.
 */
function handleHubError(error) {
  console.error(error.toString());
}

/**
 * A class for interacting with the backend services which are
 * exposed via a corresponding scrum poker hub.
 * @class
 * @category scrumpoker
 * @subcategory bi
 */
export default class ScrumPokerHubClient {
  /**
   * Creates a new instance of the {@link ScrumPokerHubClient}.
   * @param {string} hubUrl The URL of the scrum poker hub
   */
  constructor(hubUrl) {
    if (!hubUrl || typeof hubUrl !== "string")
      throw new TypeError("The hub URL must be a nonempty string.");

    /** @type {SignalR.HubConnection} */
    this.connection = new SignalR.HubConnectionBuilder().withUrl(hubUrl).build();
  }

  /** Initializes the {@link ScrumPokerHubClient}. */
  async initializeAsync() {
    try {
      await this.connection.start();
    } catch (error) {
      console.error(error.toString());
    }
  }

  /**
   * Enters the scrum poker room as the player with the given guid.
   * @param {string} id The player's guid.
   */
  async enterRoomAsync(id) {
    if (!id || typeof id !== "string") throw new TypeError("The name must be a nonempty string.");

    try {
      await this.connection.invoke(ENTER_ROOM, id);
    } catch (error) {
      handleHubError(error);
    }
  }

  /**
   * Resets the scrum poker room
   * @param {string} id The player's guid.
   */
  async resetRoomAsync(id) {
    if (!id || typeof id !== "string") throw new TypeError("The name must be a nonempty string.");

    try {
      await this.connection.invoke(RESET_ROOM, id);
    } catch (error) {
      handleHubError(error);
    }
  }

  /**
   * Changes the own player's name to the specified value.
   * @param {string} id The player's guid.
   * @param {string} name The new own player's name
   */
  async changePlayerAsync(player) {
    if (!player) throw new TypeError("The player must not be null.");
    if (!player.id || typeof player.id !== "string")
      throw new TypeError("The name must be a nonempty string.");
    if (!player.name || typeof player.name !== "string")
      throw new TypeError("The name must be a nonempty string.");

    try {
      await this.connection.invoke(CHANGE_PLAYER, player);
    } catch (error) {
      handleHubError(error);
    }
  }

  /**
   * Changes the own player's state to the specified value.
   * @param {string} id The player's guid.
   * @param {PlayerStates} state The player state to be set
   */
  async changePlayerStateAsync(id, state) {
    if (!id || typeof id !== "string") throw new TypeError("The name must be a nonempty string.");
    if (typeof state !== "number") throw new Error("The state must be a number.");
    if (state < 0 || !Number.isInteger(state))
      throw new Error("The state must be a non-negative integer representing a player state.");

    try {
      await this.connection.invoke(CHANGE_PLAYER_STATE, id, state);
    } catch (error) {
      handleHubError(error);
    }
  }

  /**
   * Changes the room's available cards to the specified array. Note:
   * The room's available cards can only be altered if the {@link RoomState} is
   * set to {@link RoomState.BetweenRound}.
   * @param {Card[]} cards An non-empty array of cards.
   */
  async changeCardValuesAsync(cards) {
    if (!cards || !Array.isArray(cards) || cards.length <= 0)
      throw new Error("The given cards must be a non-empty array of Card instances.");

    try {
      await this.connection.invoke(CHANGE_CARD_VALUES, cards);
    } catch (error) {
      handleHubError(error);
    }
  }

  /**
   * Changes the room's default round time to the specified value. Note:
   * The room's default round time can only be altered if the {@link RoomState} is
   * set to {@link RoomState.BetweenRound}.
   * @param {number} seconds New round time in seconds
   */
  async changeRoundTimeAsync(seconds) {
    if (typeof seconds !== "number") throw new Error("The round time must be a number.");
    if (seconds <= 0 || !Number.isInteger(seconds))
      throw new Error("The round time must be a positive integer representing seconds.");

    try {
      await this.connection.invoke(CHANGE_ROUND_TIME, seconds);
    } catch (error) {
      handleHubError(error);
    }
  }

  /**
   * Changes the room's topic to the specified value. Note:
   * The room's topic can only be altered if the {@link RoomState} is
   * set to {@link RoomState.BetweenRound}.
   * @param {string} topic Room topic
   */
  async changeRoomTopicAsync(topic) {
    if (!topic || typeof topic !== "string")
      throw new TypeError("The topic must be a nonempty string.");

    try {
      await this.connection.invoke(CHANGE_ROOM_TOPIC, topic);
    } catch (error) {
      handleHubError(error);
    }
  }

  /**
   * Starts a new scrum poker round. Note: A new round can only be
   * started if the {@link RoomState} is set to {@link RoomState.BetweenRound}.
   */
  async startNewRoundAsync() {
    try {
      await this.connection.invoke(START_NEW_ROUND);
    } catch (error) {
      handleHubError(error);
    }
  }

  /**
   * Stops the current scrum poker round. Note: A round can only be
   * stopped if the {@link RoomState} is set to {@link RoomState.InRound}.
   */
  async stopRoundAsync() {
    try {
      await this.connection.invoke(STOP_ROUND);
    } catch (error) {
      handleHubError(error);
    }
  }

  /**
   * Plays a {@link Card} or replaces a previously played card. Note: A card can
   * only be played if the {@link RoomState} is set to {@link RoomState.InRound}.
   * @param {string} id The player's guid.
   * @param {Card} card The card to be played.
   */
  async playCardAsync(id, card) {
    if (!id || typeof id !== "string") throw new TypeError("The name must be a nonempty string.");
    if (!card) throw new Error("The given card must be a non-null instance of class Card.");

    try {
      await this.connection.invoke(PLAY_CARD, id, card);
    } catch (error) {
      handleHubError(error);
    }
  }

  /**
   * Withdraws a {@link Card} from the poker table. Note: A card can only be
   * withdrawed from the table if the {@link RoomState} is set to
   * {@link RoomState.InRound}.
   * @param {string} id The player's guid.
   */
  async withdrawCardAsync(id) {
    if (!id || typeof id !== "string") throw new TypeError("The name must be a nonempty string.");

    try {
      await this.connection.invoke(WITHDRAW_CARD, id);
    } catch (error) {
      handleHubError(error);
    }
  }

  /** Sets a handler method for the player changed event. */
  set onPlayerChanged(handler) {
    this.connection.off(PLAYER_CHANGED);
    this.connection.on(PLAYER_CHANGED, handler);
  }

  /** Sets a handler method for the room state changed event. */
  set onRoomStateChanged(handler) {
    this.connection.off(ROOM_STATE_CHANGED);
    this.connection.on(ROOM_STATE_CHANGED, handler);
  }

  /** Sets a handler method for the room config changed event. */
  set onRoomConfigChanged(handler) {
    this.connection.off(ROOM_CONFIG_CHANGED);
    this.connection.on(ROOM_CONFIG_CHANGED, handler);
  }

  /** Sets a handler method for the current round changed event. */
  set onCurrentRoundChanged(handler) {
    this.connection.off(CURRENT_ROUND_CHANGED);
    this.connection.on(CURRENT_ROUND_CHANGED, handler);
  }

  /** Sets a handler method for the set room event. */
  set onSetRoom(handler) {
    this.connection.off(SET_ROOM);
    this.connection.on(SET_ROOM, handler);
  }

  /** Sets a handler method for the room resetted event. */
  set onRoomResetted(handler) {
    this.connection.off(ROOM_RESETTED);
    this.connection.on(ROOM_RESETTED, handler);
  }
}
