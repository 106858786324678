/**
 * Konfiguration für den Scrumpokerraum
 * @class
 * @category scrumpoker
 * @subcategory models
 */
export class RoomConfiguration {
  /**
   * Instanziiert eine neue Klasse von RoomConfiguration
   * @param {String} topic Das Thema des Scrumpoker-Raumes
   * @param {Number} roundTimeInSeconds Die Rundenzeit ein Sekunden
   * @param {Card[]} cards Menge der zur Verfügung stehenden Spielkarten
   */
  constructor(topic = "", roundTimeInSeconds = 0, cards = []) {
    /**
     * Thema der aktuellen Runde
     * @type {String} */
    this.topic = topic;
    /**
     * Dauer der aktuellen Runde (Wieviel Zeit hat ein Spieler, um seine Stimme abzugeben) in Sekunden
     *  @type {Number} */
    this.roundTimeInSeconds = roundTimeInSeconds;
    /**
     * Die Werte der Karten, welche in der Runde verwendet werden können (Die Reihenfolge/Wertigkeit ergibt sich über den Index)
     *  @type {Card[]} */
    this.cards = cards;
  }
}
