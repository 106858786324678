import { v4 as uuidv4 } from "uuid";
import { PlayerStates } from "./playerStates";
/**
 * Repräsentiert einen Spieler im Scrumpoker
 * @class
 * @category scrumpoker
 * @subcategory models
 */
export class Player {
  /**
   * Instanziiert eine neue Klasse von Player
   */
  constructor(id = uuidv4(), name = "", state = PlayerStates.none) {
    /**
     * Eindeutige Id des Spielers
     * @type {string}
     */
    this.id = id;

    /**
     * Name des Spielers
     * @type {string}
     */
    this.name = name;

    /**
     * Status des Spielers
     * @type {number} */
    this.state = state;

    /** @type {Boolean} */
    Object.defineProperty(this, "isValid", {
      enumerable: false,
      get: function () {
        return this.name && this.name.length > 0 && this.name.length <= 64;
      }
    });
  }
}
